<template>
  <div class="ExamSchoolTask">
    <detailsHeader ref="detailsHeader"></detailsHeader>
    <div class="tab-box">
      <div class="tips">
        <img src="@/assets/uExam/icon_waring.png" alt="" />
        学校任务量:限制学校全部阅卷教师阅卷量之和不超过输入数值。绑定批阅的题目统一设置。
      </div>
      <div class="cnt-box">
        <div class="question-box">
          <div class="question-header">待设置试题</div>
          <div class="question-list edit-scroll-style">
            <el-radio-group v-model="indexQuestion" @change="getSchoolWork()">
              <template v-for="(item, index) in exammarkList">
                <template v-if="questionDis(item)">
                  <el-tooltip
                    :key="index"
                    class="item"
                    effect="dark"
                    :content="item.bindQuestionNum + '为绑定批阅'"
                    placement="top"
                    :open-delay="500"
                  >
                    <el-radio :label="item.sheetDetailId" disabled>
                      <template v-if="item.isOption == 1">选做题</template>
                      <template v-else>主观题</template> {{ item.questionNum }}
                    </el-radio>
                  </el-tooltip>
                </template>
                <el-radio v-else :key="index" :label="item.sheetDetailId">
                  <template v-if="item.isOption == 1">选做题</template>
                  <template v-else>主观题</template> {{ item.questionNum }}
                </el-radio>
              </template>
            </el-radio-group>
          </div>
        </div>
        <div class="set-box">
          <div>
            正常卡人数/考生人数：{{ sheetCount.sheetCount }} /
            {{ sheetCount.examStudentCount }}
          </div>
          <div class="set-item">
            批量设置学校任务量：
            <el-radio v-model="type" :label="0"> 按考生数量</el-radio>
            <el-input
              v-model="stuNums0"
              placeholder=""
              :disabled="type !== 0"
              oninput="value=value.replace(/[^0-9]/g,'')"
              @blur="stuNums0 = $event.target.value"
            ></el-input>
            %设置

            <el-radio v-model="type" :label="1"> 按有效卷数量</el-radio>
            <el-input
              v-model="stuNums1"
              placeholder=""
              :disabled="type != 1"
              oninput="value=value.replace(/[^0-9]/g,'')"
              @blur="stuNums1 = $event.target.value"
            ></el-input>
            %设置
            <el-radio v-model="type" :label="2"> 固定任务量</el-radio>
            <el-input
              v-model="stuNums2"
              placeholder=""
              :disabled="type != 2"
              oninput="value=value.replace(/[^0-9]/g,'')"
              @blur="stuNums2 = $event.target.value"
            ></el-input>
            <el-button
              type="primary"
              style="margin-left: 12px"
              :disabled="!(type > 0 || type === 0)"
              @click="applyNums()"
              >应用</el-button
            >
          </div>
          <div>
            <el-table
              v-loading="tabLoading"
              :data="schoolItem"
              border
              style="width: 100%"
            >
              <el-table-column
                prop="schoolName"
                label="学校名称"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="examStudentCount"
                label="考生人数"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="normalAnwserSheet"
                label="有效卷数量"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="readCount"
                label="教师已阅量"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="address" label="学校任务量" align="center">
                <template slot-scope="{ row }">
                  <el-input
                    v-model="row.workload"
                    placeholder=""
                    style="width: 160px"
                    oninput="value=value.replace(/[^0-9]/g,'')"
                    @blur="row.workload = $event.target.value"
                  ></el-input>
                  <template v-if="getShow()">&nbsp;&nbsp; * 2</template>
                </template>
              </el-table-column>
            </el-table>
            <div class="btn-box">
              <el-button
                size="medium"
                type="primary"
                :loading="btnLoading1"
                :disabled="schoolItem.length == 0"
                @click="submit(1)"
                >适用到所选题目</el-button
              >
              <el-button
                size="medium"
                type="primary"
                :loading="btnLoading2"
                :disabled="schoolItem.length == 0"
                @click="showModel(2)"
                >适用到其他题目</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 复制阅卷老师 -->
    <el-dialog title="选择题目" :visible.sync="dialogVisible" width="650px">
      <div class="model-teacher-box">
        <div class="model-teacher-box-btn">
          <el-button type="text" @click="checkListChange('checkAll')"
            >全选</el-button
          >
          <el-button type="text" @click="checkListChange('checkFalse')"
            >反选</el-button
          >
          <el-button type="text" @click="checkListChange('checkCancel')"
            >取消</el-button
          >
        </div>
        <div>
          <el-checkbox-group v-model="checkList">
            <template v-for="(v, i) in exammarkList">
              <template
                v-if="indexQuestion == v.sheetDetailId || questionDis(v)"
              >
                <el-tooltip
                  :key="i"
                  class="item"
                  effect="dark"
                  :content="
                    indexQuestion == v.sheetDetailId
                      ? '当前已选试题'
                      : v.bindQuestionNum + '为绑定批阅'
                  "
                  placement="top"
                  :open-delay="500"
                >
                  <el-checkbox :label="v.sheetDetailId" disabled>
                    主观题 {{ v.questionNum }}
                  </el-checkbox>
                </el-tooltip></template
              >
              <el-checkbox v-else :key="i" :label="v.sheetDetailId">
                <template v-if="v.isOption == 1">选做题</template>
                <template v-else>主观题</template>
                {{ v.questionNum }}
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading2"
          @click="submitModel(2)"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import detailsHeader from "@/components/detailsHeader.vue";
import { getObj } from "@/core/api/exam/exam";
import {
  exammarkTeacherList,
  schoolWorkloadv2,
  saveSchoolWorkloadv2,
  exammarkUnionSheet,
} from "@/core/api/exam/examCorrectTask";
import { getStore } from "@/core/util/store";
export default {
  name: "ExamSchoolTask",
  components: { detailsHeader },
  data() {
    return {
      examInformation: {},
      sheetCount: {},
      indexQuestion: "",
      exammarkList: [],
      schoolItem: [],
      checkList: [],
      type: "",
      stuNums1: "",
      stuNums2: "",
      stuNums0: "",
      tabLoading: false,
      btnLoading1: false,
      btnLoading2: false,
      btnLoading: false,
      dialogVisible: false,
    };
  },
  async created() {
    this.getExamInfo();
    await this.exammarkTeacherList();
    await this.exammarkUnionSheet();
    this.getSchoolWork();
  },
  methods: {
    showModel() {
      this.dialogVisible = true;
      this.checkList = [];
    },
    submitModel() {
      if (this.checkList.length == 0) {
        this.$message({
          showClose: true,
          message: `请选择需要适用的题目`,
          type: "warning",
        });
        return;
      }
      this.submit(2);
    },
    // 全选 反选 取消
    checkListChange(val) {
      if ("checkAll" == val) {
        let arr = [];
        this.exammarkList.forEach((item) => {
          if (item.bindQuestionNum) {
            if (
              item.sheetDetailId != this.indexQuestion &&
              item.bindQuestionNum.indexOf(item.questionNum) == 0
            ) {
              arr.push(item.sheetDetailId);
            }
          } else {
            if (item.sheetDetailId != this.indexQuestion) {
              arr.push(item.sheetDetailId);
            }
          }
        });
        this.checkList = arr;
      }

      if ("checkFalse" == val) {
        this.checkCancel = false;
        this.checkAll = false;
        let arr = [];
        this.exammarkList.forEach((item) => {
          if (item.bindQuestionNum) {
            if (
              this.checkList.indexOf(item.sheetDetailId) == -1 &&
              item.sheetDetailId != this.indexQuestion &&
              item.bindQuestionNum.indexOf(item.questionNum) == 0
            ) {
              arr.push(item.sheetDetailId);
            }
          } else {
            if (
              this.checkList.indexOf(item.sheetDetailId) == -1 &&
              item.sheetDetailId != this.indexQuestion
            ) {
              arr.push(item.sheetDetailId);
            }
          }
        });

        this.checkList = arr;
      }
      if ("checkCancel" == val) {
        this.checkFalse = false;
        this.checkAll = false;
        this.checkList = [];
      }
      // console.log(this.checkList);
    },
    exammarkUnionSheet() {
      let data = {
        examPaperId: this.$route.query.paperId,
      };
      if (this.$route.query.examType == 1) {
        data.schoolId = this.schoolId || "-1";
        if (this.$route.name != "ExamCorrectTask") {
          data.schoolId = getStore({ name: "school_id" });
        }
      }
      exammarkUnionSheet(data)
        .then((res) => {
          this.sheetCount = res.data.data;
        })
        .catch(() => {
          // clearInterval(this.getNums);
        });
    },
    async exammarkTeacherList() {
      let data = {
        examPaperId: this.$route.query.paperId,
        schoolId: "",
      };
      this.listLoading = true;
      await exammarkTeacherList(data)
        .then((res) => {
          this.exammarkList = res.data.data;
          this.indexQuestion = res.data.data[0].sheetDetailId;

          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    questionDis(item) {
      let type = false;
      if (
        item.bindQuestionNum &&
        item.bindQuestionNum.split(",").indexOf(item.questionNum) != 0
      ) {
        type = true;
      }
      return type;
    },
    getSchoolWork() {
      let data = {
        examPaperId: this.$route.query.paperId,
        sheetDetailId: this.indexQuestion,
        groupId: this.$route.query.groupId,
      };
      this.tabLoading = true;
      this.schoolItem = [];
      schoolWorkloadv2(data)
        .then((res) => {
          this.schoolItem = res.data.data;

          if (this.schoolItem.length > 0) {
            let arr = this.schoolItem.filter(
              (item) => item.workload > 0 || item.workload === 0
            );
            if (arr.length == 0) {
              this.type = 0;
              this["stuNums" + this.type] = 100;
              // this.setValue("stuNums" + this.type);
            } else {
              this.type = "";
              this.stuNums0 = "";
              this.stuNums1 = "";
              this.stuNums2 = "";
            }
          }
          // this.schoolItem.map((item) => {
          //   this.type = item.type;
          //   if (this.type == 2) {
          //     this["stuNums" + this.type] = item.taskWorkload;
          //   } else {
          //     this["stuNums" + this.type] = item.taskRate;
          //   }
          // });
          this.tabLoading = false;
        })
        .catch(() => {
          this.tabLoading = false;
        });
    },
    applyNums() {
      // @change="setValue('stuNums0')"
      if (this.type == 0 || this.type == 1) {
        if (Number(this["stuNums" + this.type]) > 100000000) {
          this.$message({
            showClose: true,
            message: `请输入小于100000的数值`,
            type: "warning",
          });
          return;
        }
      }

      this.setValue("stuNums" + this.type);
    },
    getShow() {
      let ruleScore = 1;
      this.exammarkList.map((item) => {
        if (this.indexQuestion == item.sheetDetailId) {
          ruleScore = item.ruleScore;
        }
      });
      return ruleScore == 1 ? false : true;
    },
    setValue(key) {
      //           this.exammarkList = res.data.data;
      // this.indexQuestion = res.data.data[0].sheetDetailId;
      // let ruleScore = 1;
      // this.exammarkList.map((item) => {
      //   if (this.indexQuestion == item.sheetDetailId) {
      //     ruleScore = item.ruleScore;
      //   }
      // });
      if (this.type == 0) {
        this.schoolItem = this.schoolItem.map((item) => {
          item.workload = parseInt((this[key] / 100) * item.examStudentCount);
          // if (ruleScore != 1) {
          //   item.workload = item.workload * 2;
          // }
          return item;
        });
      }
      if (this.type == 1) {
        this.schoolItem = this.schoolItem.map((item) => {
          item.workload = parseInt(
            (this[key] / 100) * (item.normalAnwserSheet || 0)
          );
          // if (ruleScore != 1) {
          //   item.workload = item.workload * 2;
          // }
          return item;
        });
      }
      if (this.type == 2) {
        this.schoolItem = this.schoolItem.map((item) => {
          item.workload = parseInt(this[key] || 0);
          return item;
        });
      }
    },
    submit(type) {
      let arr = [];
      let arr1 = [];
      this.schoolItem.map((item) => {
        if (item.workload === "" || item.workload === null) {
          arr.push(item.schoolName);
        }
        if (item.workload && Number(item.workload) > 100000000) {
          arr1.push(item.schoolName);
        }
      });
      if (arr.length > 0) {
        this.$message({
          showClose: true,
          message: `请设置 ${arr.join(",")} 学校任务量！`,
          type: "warning",
        });
        return;
      }
      if (arr1.length > 0) {
        this.$message({
          showClose: true,
          message: `${arr1.join(",")} 学校任务量不能大于‘100000000’！`,
          type: "warning",
        });
        return;
      }
      if (type == 1) {
        let array = [this.indexQuestion];
        let arr = [];
        this.exammarkList.map((item) => {
          if (array.indexOf(item.sheetDetailId) != -1) {
            if (item.bindQuestionNum) {
              arr.push(item.sheetDetailId);
              this.exammarkList.map((index) => {
                if (item.bindQuestionNum.indexOf(index.questionNum) > 0) {
                  arr.push(index.sheetDetailId);
                }
              });
            } else {
              arr.push(item.sheetDetailId);
            }
          }
        });
        this.setQuestion(arr, type);
      } else {
        let arr = [];
        let checkArr = this.checkList;
        // console.log(checkArr);
        checkArr.push(this.indexQuestion);
        this.exammarkList.map((item) => {
          if (checkArr.indexOf(item.sheetDetailId) != -1) {
            if (item.bindQuestionNum) {
              arr.push(item.sheetDetailId);
              this.exammarkList.map((index) => {
                if (item.bindQuestionNum.indexOf(index.questionNum) > 0) {
                  arr.push(index.sheetDetailId);
                }
              });
            } else {
              arr.push(item.sheetDetailId);
            }
          }
        });
        // console.log(checkArr);
        // console.log(arr);
        arr.push(this.indexQuestion);
        arr = [...new Set(arr)];
        this.setQuestion(arr, type);
      }
    },
    setQuestion(arr, type) {
      let array = this.schoolItem.map((item) => {
        item.sheetDetailIds = arr;
        item.type = this.type;
        item.examPaperId = this.$route.query.paperId;
        item.groupId = this.$route.query.groupId;
        return item;
      });

      this["btnLoading" + type] = true;
      saveSchoolWorkloadv2(array)
        .then(() => {
          this.$message({
            showClose: true,
            message: "保存成功！",
            type: "success",
          });
          this["btnLoading" + type] = false;
          this.dialogVisible = false;
        })
        .catch(() => {
          this["btnLoading" + type] = false;
        });
    },
    //获取考试基本信息
    getExamInfo() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        this.paperList = res.data.data.paperList;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.ExamSchoolTask {
  padding-top: 18px;
  margin-bottom: 40px;
  .el-checkbox {
    min-width: 25%;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 18px;
  }
  .tab-box {
    padding: 18px;
    background-color: #ffffff;
    margin-top: 18px;
    .cnt-box {
      display: flex;
      .btn-box {
        margin: 18px auto;
        text-align: center;
      }

      .set-box {
        width: calc(100% - 258px);
        > div {
          margin-bottom: 18px;
        }
        .set-item {
          .el-radio {
            margin-left: 18px;
            margin-right: 8px;
            ::v-deep {
              .el-radio__label {
                padding-left: 4px;
              }
            }
          }
          .el-input {
            width: 80px;
          }
        }
      }
      .question-box {
        width: 240px;
        border-radius: 4px;
        border: 1px solid #dee0e7;
        overflow: hidden;
        margin-right: 18px;
        .question-header {
          padding: 12px 18px;
          border-bottom: 1px solid #dee0e7;
          background: #f5f7fa;
        }
        .question-list {
          padding: 12px 18px;
          height: 742px;
          .el-radio {
            display: block;
            margin-bottom: 18px;
          }
        }
      }
    }
    .tips {
      padding: 13px 18px;
      color: #292626;
      background: #fff3f3;
      border-radius: 4px;
      display: flex;
      align-items: center;
      line-height: 14px;
      margin-bottom: 18px;
      img {
        margin-right: 8px;
      }
    }
  }
}
</style>
